import { useSpring, useTransition, animated, config } from '@react-spring/web'
import { useWasInViewport } from '@kaliber/use-is-in-viewport'
import { determineDocumentPathSync } from '@kaliber/sanity-routing/sanity'
import { useScrollProgression, triggers } from '@kaliber/scroll-progression'
import { lerp, sequence } from '@kaliber/math'

import { routeMap } from '/routeMap'
import { easeInOutQuart, easeOutQuad } from '/machinery/easings'

import { HeadingXl } from '/features/buildingBlocks/Heading'
import arrowRight from '/images/icons/arrow-right.raw.svg'
import { Icon } from '/features/buildingBlocks/Icon'
import { ContainerMd } from '/features/buildingBlocks/Container'
import { ImageCover } from '/features/buildingBlocks/Image'

import styles from './DisciplineAreaList.css'

export function DisciplineAreaList({ title = undefined, disciplines }) {
  const { ref: elementRef, wasInViewport } = useWasInViewport({ threshold: [1] })
  const [activeDiscipline, setActiveDiscipline] = React.useState(disciplines ? disciplines[0] : null)
  const [visibilityMap, setVisibilityMap] = React.useState(() => new Map(disciplines?.map(x => [x, 0]) ?? []))

  React.useEffect(
    () => {
      setVisibilityMap(new Map(disciplines?.map(x => [x, 0]) ?? []))
    },
    [disciplines]
  )

  const headingSpring = useSpring({
    to: {
      opacity: wasInViewport ? 1 : 0,
      transform: wasInViewport ? 'translateY(0)' : 'translateY(40px)'
    },
    delay: 100,
    config: { tension: 100, friction: 35 }
  })

  const images = disciplines?.map(x => x?.hero?.image)

  return disciplines?.length >= 1 && (
    <div className={styles.component}>
      {title && (
        <ContainerMd layoutClassName={styles.containerLayout}>
          <animated.div className={styles.headingContainer} style={headingSpring} ref={elementRef}>
            <HeadingXl h='2'>{title}</HeadingXl>
          </animated.div>
        </ContainerMd>
      )}

      <ContainerMd layoutClassName={styles.listContainerLayout}>
        <ul className={styles.list}>
          {disciplines?.map((x, index) => (
            <li key={x._id} className={styles.listItem}>
              <Discipline
                title={x.title}
                description={x.shortDescription}
                href={determineDocumentPathSync({ document: x, routeMap })}
                onVisibilityChange={visibility => updateVisibility(x, visibility)}
                activeDiscipline={disciplines.indexOf(activeDiscipline)}
                layoutClassName={styles.discipline}
                {... { index }}
              />
            </li>
          ))}
        </ul>
      </ContainerMd>

      <CrossFade
        image={activeDiscipline.hero?.image}
        layoutClassName={styles.crossFadeLayout}
        {...{ activeDiscipline, disciplines, images }}
      />
    </div>
  )

  function updateVisibility(x, visibility) {
    visibilityMap.set(x, visibility)

    const [discipline] = Array.from(visibilityMap.entries()).reduce(
      (result, x) => {
        const [, visibility] = x
        const [, maxVisibility] = result
        return visibility > maxVisibility ? x : result
      },
      [activeDiscipline, 0]
    )

    setActiveDiscipline(discipline)
  }
}

function CrossFade({ images, image, layoutClassName, disciplines, activeDiscipline }) {
  const [{ scale }, scaleSpring] = useSpring(() => ({ scale: 1.1, config: { tension: 100, friction: 35 } }))
  const [{ clip }, clipSpring] = useSpring(() => ({ clip: 0, config: { tension: 100, friction: 55 } }))
  const imageRef = useScrollProgression({
    start: { element: triggers.top(), scrollParent: triggers.bottom() },
    end: { element: triggers.top(), scrollParent: triggers.custom(0.5) },
    onChange(progression) {
      scaleSpring.start({ scale: lerp({ start: 1.1, end: 1, input: easeOutQuad(progression) }) })
      clipSpring.start({ clip: progression })
    }
  })

  const transitions = useTransition(image, {
    from: { opacity: 0 },
    enter: { opacity: 0.8 },
    leave: { opacity: 0 },
    config: config.slow
  })

  return (
    <animated.div ref={imageRef} className={cx(styles.componentCrossFade, layoutClassName)} style={{
      clipPath: clip.to(x => {
        const pos = lerp({ start: 80, end: 100, input: x })
        const neg = lerp({ start: 20, end: 0, input: x })
        return `polygon(${neg}% ${neg}%, ${pos}% ${neg}%, ${pos}% ${pos}%, ${neg}% ${pos}%)`
      })
    }}>
      {transitions((style, image) => (
        <animated.div className={styles.crossFadeItem} style={{ ...style, scale }}>
          {image && <ImageCover layoutClassName={cx(styles.image, styles.desktopVersion)} aspectRatio={16 / 9} sizes='100vw' imgProps={{ loading: 'lazy' }} {...{ image }} />}
          {image && <ImageCover layoutClassName={cx(styles.image, styles.mobileVersion)} aspectRatio={9 / 16} sizes='100vw' imgProps={{ loading: 'lazy' }} {...{ image }} />}
        </animated.div>
      ))}

      {/* Preload images */}
      {Boolean(images.length) && images.map(x => (
        <React.Fragment key={x.asset._ref}>
          {x && <ImageCover layoutClassName={cx(styles.preloadImage, styles.desktopVersion)} aspectRatio={16 / 9} sizes='100vw' imgProps={{ loading: 'lazy' }} image={x} />}
          {x && <ImageCover layoutClassName={cx(styles.preloadImage, styles.mobileVersion)} aspectRatio={9 / 16} sizes='100vw' imgProps={{ loading: 'lazy' }} image={x} />}
        </React.Fragment>
      ))}
      <animated.div style={{ scale }} className={styles.navigation}>
        <Navigation itemCount={disciplines.length} activeDiscipline={disciplines.indexOf(activeDiscipline)}  />
      </animated.div>
      <div className={styles.gradient} role="presentation" />
    </animated.div>
  )
}

function Navigation({ activeDiscipline, itemCount }) {
  return (
    sequence(itemCount).map(index => (
      <div key={index} className={cx(styles.componentNavigation, activeDiscipline === index && styles.isActive)} />
    ))
  )
}

function Discipline({ title, description, href, onVisibilityChange, activeDiscipline, index, layoutClassName = undefined }) {
  const [{ opacity }, springApi] = useSpring(() => ({ opacity: 0.5 }))
  const trackedElementRef = useScrollProgression({
    start: { element: triggers.center(), scrollParent: triggers.bottom() },
    end: { element: triggers.center(), scrollParent: triggers.top() },
    onChange(progression) {
      const visibility = 1 - Math.abs(lerp({ start: -1, end: 1, input: progression }))
      onVisibilityChange(visibility)
      springApi.set({ opacity: lerp({ start: 0.2, end: 1, input: easeInOutQuart(visibility) }) })
    }
  })

  const innerDisciplineSpring = useSpring({
    from: { transform: 'translateX(-40px)' },
    to: { transform: activeDiscipline === index ? 'translateX(0px)' : 'translateX(-40px)' },
  })

  const iconSpring = useSpring({
    from: { opacity: 0, transform: 'translateX(-40px)' },
    to: {
      transform: activeDiscipline === index ? 'translateX(0px)' : 'translateX(-40px)',
      opacity: activeDiscipline === index ? 1 : 0,
    },
  })

  return (
    <animated.div ref={trackedElementRef} className={styles.componentDiscipline} style={{ opacity }}>
      <animated.div className={cx(styles.innerDiscipline, styles.relativeToParent)} style={innerDisciplineSpring}>
        <HeadingXl h='3' layoutClassName={cx(styles.heading, styles.relativeToParent)}>
          <a data-x="link-to-discipline-page" className={cx(styles.link, styles.relativeToParent)} {...{ href }}>{title}</a>
        </HeadingXl>
        <p className={styles.description}>{description}</p>
      </animated.div>
      <animated.div className={styles.icon} style={iconSpring}><Icon layoutClassName={styles.innerIcon} icon={arrowRight} /></animated.div>
    </animated.div>
  )
}
